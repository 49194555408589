import { convertNullIfEmpty } from "@/utils/null-check";

class UserModel {
    static getToEdit(e) {
        return {
            name: "" || e.name,
            email: "" || e.user?.email || "",
            roleId: "" || e.rolePermission.id,

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
    static postPayload(e) {
        return {
            name: "" || e.name,
            email: "" || e.email,
            roleId: "" || e.roleId,
            password: "" || e.password,

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
    static putPayload(e) {
        return {
            name: "" || e.name,
            email: "" || e.email,
            roleId: "" || e.roleId,

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
}

export default UserModel;
