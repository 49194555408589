import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import projectRoutes from "./projectRoutes";
import projectSalesRoutes from "./projectSalesRoutes";

const auth = true;

const routes = [
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/forgotPassword"),
        meta: {
            auth: false
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/index"),
        meta: {
            auth: false
        }
    },
    {
        path: "/",
        name: "Entry",
        component: () => import("@/views/entry"),
        redirect: { name: "ManageProjects" },
        meta: {
            auth: auth
        },
        children: [
            ...projectRoutes,
            /* =================================================================
             *                         Project Sales
             * =================================================================
             */
            ...projectSalesRoutes,

            /* =================================================================
             *                        Project Reports
             * =================================================================
             */
            {
                path: "/projects/:projectId/reports",
                name: "ProjectReports",
                component: () => import("@/views/projectReports"),
                meta: {
                    auth: auth,
                    permission: "manageProjects.report"
                },
                redirect: { name: "ProjectSalesReport" },
                children: [
                    {
                        path: "/projects/:projectId/reports/sales",
                        name: "ProjectSalesReport",
                        component: () => import("@/views/projectReports/sales"),
                        meta: {
                            auth: auth,
                            permission: "manageProjects.report"
                        }
                    },
                    {
                        path: "/projects/:projectId/reports/aging",
                        name: "ProjectAgingReport",
                        component: () => import("@/views/projectReports/aging"),
                        meta: {
                            auth: auth,
                            permission: "manageProjects.report"
                        }
                    }
                ]
            },
            /* =================================================================
             *                            Users
             * =================================================================
             */
            {
                path: "/users",
                name: "ManageUsers",
                component: () => import("@/views/users"),
                meta: {
                    auth: auth,
                    permission: "manageUsers"
                }
            },
            {
                path: "/users/:id(\\d+)",
                name: "ManageUsersDetail",
                component: () => import("@/views/users/detail"),
                meta: {
                    auth: auth,
                    permission: "manageUsers"
                }
            },
            {
                path: "/users/add",
                name: "ManageUsersAdd",
                component: () => import("@/views/users/add"),
                meta: {
                    auth: auth,
                    permission: "manageUsers"
                }
            },

            {
                path: "/users/:id/edit",
                name: "ManageUsersEdit",
                component: () => import("@/views/users/edit"),
                meta: {
                    auth: auth,
                    permission: "manageUsers"
                }
            },

            /* =================================================================
             *                            Defects
             * =================================================================
             */
            // {
            //     path: "/projects/defects",
            //     name: "ManageDefects",
            //     component: () => import("@/views/manageProjectUnitDefects"),
            //     meta: {
            //         auth: auth
            //     },
            //     redirect: {
            //         name: "ManageDefectsPending"
            //     },
            //     children: [
            //         {
            //             path: "pending",
            //             name: "ManageDefectsPending",
            //             component: () =>
            //                 import("@/views/manageProjectUnitDefects/pending"),
            //             props: {
            //                 default: true,
            //                 mode: "pending"
            //             },
            //             meta: {
            //                 auth: auth
            //             }
            //         },
            //         {
            //             path: "in-progress",
            //             name: "ManageDefectsInProgress",
            //             component: () =>
            //                 import(
            //                     "@/views/manageProjectUnitDefects/inProgress"
            //                 ),
            //             props: {
            //                 default: true,
            //                 mode: "inProgress"
            //             },
            //             meta: {
            //                 auth: auth
            //             }
            //         },
            //         {
            //             path: "history",
            //             name: "ManageDefectsHistory",
            //             component: () =>
            //                 import("@/views/manageProjectUnitDefects/history"),
            //             props: {
            //                 default: true,
            //                 mode: "history"
            //             },
            //             meta: {
            //                 auth: auth
            //             }
            //         },
            //         {
            //             path: ":id",
            //             name: "ManageDefectsDetail",
            //             component: () =>
            //                 import(
            //                     "@/views/manageProjectUnitDefects/detail/_detail"
            //                 ),
            //             meta: {
            //                 auth: auth
            //             }
            //         }
            //     ]
            // },
            /* =================================================================
             *                            Account
             * =================================================================
             */
            {
                path: "/account",
                name: "ManageAccount",
                component: () => import("@/views/manageAccount"),
                meta: {
                    auth: auth
                }
            }
        ]
    },
    /* =========================================================================
     *                              Miscellaneous
     * =========================================================================
     */
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/views/maintenance"),
        meta: {
            auth: undefined
        }
    },
    {
        path: "/*",
        name: "NotFound",
        component: () => import("@/views/404/NotFound"),
        meta: {
            auth: undefined
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

Vue.router = router;

import permissionMiddleware from "./middleware/permission";
import store from "@/store";

router.beforeEach((to, from, next) => {
    let isMaintenance = process.env.VUE_APP_MAINTENANCE_MODE;
    if (isMaintenance == 1) {
        if (to.name == "Maintenance") {
            next();
            return;
        }
        next({ name: "Maintenance" });
        return;
    }

    // Notification hack (Fetch once every route change)
    if (Vue.prototype.$auth.check()) {
        store.dispatch("checkHasUnreadNotification");
    }

    permissionMiddleware({ to, next });
    return;
});

export default router;
