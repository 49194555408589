import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import qsStringify from "qs/lib/stringify";
import { cleanQueryParam } from "@/utils/filter";

Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.defaults.baseURL = process.env.VUE_APP_API_URL.replace(/\/$/, "");
axios.defaults.paramsSerializer = function (params) {
    params = cleanQueryParam(params);
    return qsStringify(params, { arrayFormat: "brackets" });
};
axios.defaults.headers.common = {
    "pms-cpanel-version": process.env.VUE_APP_VERSION
};

import { errorHandler } from "@/utils/error/apiExceptionHandler";

axios.interceptors.response.use(
    function (res) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return Promise.resolve(res);
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    errorHandler
);
