import moment from "moment";

class ProjectReservationsModel {
    static postApprovePayload(e) {
        return {
            paymentDueDate: moment(e.paymentDueDate).format("YYYY-MM-DD")
        };
    }
}

export default ProjectReservationsModel;
