import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { downloadFile } from "@/utils/download";

async function getBookings(status, { queries = {}, page = 1, perPage = 30 }) {
    let include =
        "projectPurchaseBooking,projectPurchaseReservation,projectUnit,projectUnitType,projectUnitFloorPlan,project,appUser,agent";
    try {
        const URL = `${developerAPI}/project-purchase/bookings`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                "projectPurchaseBooking:status": status,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get P.Bookings (${status})`);
        throw error;
    }
}

async function getBookingDetail(id) {
    let include =
        "projectPurchaseBooking,projectUnit,projectUnitType,projectUnitFloorPlan,project,appUser,projectPurchaseReservation,agent";

    try {
        const URL = `${developerAPI}/project-purchase/bookings/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get P.Booking Detail [ID: ${id}]`);
        throw error;
    }
}

async function approveBooking(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase/bookings/${id}/approved`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Approve P.Booking [ID: ${id}]`);
        throw error;
    }
}

async function rejectBooking(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase/bookings/${id}/rejected`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Reject P.Booking [ID: ${id}]`);
        throw error;
    }
}

async function cancelBooking(id) {
    try {
        const URL = `${developerAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Cancel P.Booking [ID: ${id}]`);
        throw error;
    }
}

async function markAsBookedByOther(id) {
    try {
        const URL = `${developerAPI}/project-purchase/bookings/${id}/booked-by-other`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] P.Booking BookedByOther [ID: ${id}]`);
        throw error;
    }
}

// ======================= GET REJECTED/CANCELLED REASON =======================
const REJECTED = "Booking Rejected";
const CANCELLED = "Booking Cancelled";

async function getRejectedReason(id) {
    try {
        let data = await getReason(id, REJECTED);
        return data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Booking Rejected Reason`);
        throw error;
    }
}
async function getCancelledReason(id) {
    try {
        let data = await getReason(id, CANCELLED);
        return data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Booking Rejected Reason`);
        throw error;
    }
}

async function getReason(id, action) {
    const URL = `${developerAPI}/project-purchase/project-purchase-tracking-log`;

    const response = await axios.get(URL, {
        params: {
            action: action,
            "projectPurchase:id": id,
            page: 1,
            limit: 1
        }
    });

    return response.data.data[0];
}

export default {
    getBookings,
    getBookingDetail,
    approveBooking,
    rejectBooking,
    cancelBooking,
    markAsBookedByOther,
    getRejectedReason,
    getCancelledReason,
    downloadFile
};
