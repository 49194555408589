export default [
    /* =================================================================
     *                     Manage Agency Project Sale
     * =================================================================
     */
    {
        path: "/projects/sales",
        name: "ManageProjectSales",
        component: () => import("@/views/projectSales/manageProjectSales"),
        redirect: { name: "ManageProjectSalesOverview" },
        meta: {
            auth: true,
            permission: "manageProjectSales"
        },
        children: [
            {
                path: "overview",
                name: "ManageProjectSalesOverview",
                component: () =>
                    import("@/views/projectSales/manageProjectSales/overview"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // ==================== Reservations ====================
            {
                path: "manage-reservation",
                name: "ProjectReservationsManagement",
                component: {
                    render: (c) => c("router-view")
                },
                redirect: { name: "ViewReservedUnits" },
                children: [
                    {
                        path: "reserved-units",
                        name: "ViewReservedUnits",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectReservations/reservedUnits"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    },
                    {
                        path: "unit/:id/reservation",
                        name: "ManageProjectReservations",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectReservations"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    },
                    {
                        path: "unit/:unitId/reservation/:id",
                        name: "ManageProjectReservationDetails",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectReservations/details"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    }
                ]
            },
            // ==================== Bookings ====================
            {
                path: "manage-bookings",
                name: "ManageProjectBooking",
                component: () =>
                    import("@/views/projectSales/manageProjectBookings"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                },
                redirect: { name: "ManageProjectBookingPending" },
                children: [
                    {
                        path: "pending",
                        name: "ManageProjectBookingPending",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectBookings/pendingBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    },
                    {
                        path: "rejected",
                        name: "ManageProjectBookingRejected",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectBookings/rejectedBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    },
                    {
                        path: "booked-by-other",
                        name: "ManageProjectBookingBookedByOther",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectBookings/bookedByOtherBooking"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    },
                    {
                        path: ":id",
                        name: "ManageProjectBookingDetail",
                        component: () =>
                            import(
                                "@/views/projectSales/manageProjectBookings/detail/_detail"
                            ),
                        meta: {
                            auth: true,
                            permission: "manageProjectSales"
                        }
                    }
                ]
            },
            // ======================== Mortgage =======================
            {
                path: "mortgage",
                name: "ManageProjectMortgage",
                redirect: { name: "ManageProjectMortgageBankLoan" },
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "mortgage/bank-loan",
                name: "ManageProjectMortgageBankLoan",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectMortgage/bankLoan"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "mortgage/bank-loan/:id(\\d+)",
                name: "ManageProjectMortgageBankLoansDetail",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectMortgage/bankLoan/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "mortgage/bank-loan/history",
                name: "ManageProjectMortgageBankLoanHistory",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectMortgage/bankLoan/history"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "mortgage/other",
                name: "ManageProjectMortgageOther",
                component: () =>
                    import("@/views/projectSales/manageProjectMortgage/other"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "mortgage/other/:id",
                name: "ManageProjectMortgageOtherDetail",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectMortgage/other/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // ======================== Lawyer =========================
            {
                path: "legal-process",
                name: "ManageProjectLegalProcess",
                component: () =>
                    import("@/views/projectSales/manageProjectLegalProcess"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "legal-process/:id",
                name: "ManageProjectLegalProcessDetail",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectLegalProcess/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // =================== Vacant Possession ===================
            {
                path: "vacant-posession",
                name: "ManageProjectVacantPossession",
                component: () =>
                    import("@/views/projectSales/manageProjectVacantPosession"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "vacant-posession/:id",
                name: "ManageProjectVacantPossessionDetails",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectVacantPosession/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // ========================= Refund ========================
            {
                path: "refund",
                name: "ManageProjectBookingRefunds",
                component: () =>
                    import("@/views/projectSales/manageProjectBookingRefunds"),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "refund/:id",
                name: "ProjectRefundDetails",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectBookingRefunds/detail/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // Refund history
            {
                path: "refund/history",
                name: "ProjectRefundHistory",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectBookingRefunds/history/_history"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            {
                path: "refund/history/:id",
                name: "ProjectRefundHistoryDetails",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectBookingRefunds/history/_detail"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            },
            // =================================================================
            {
                path: ":id",
                name: "ProjectSalesDetail",
                component: () =>
                    import(
                        "@/views/projectSales/manageProjectSales/_detail.vue"
                    ),
                meta: {
                    auth: true,
                    permission: "manageProjectSales"
                }
            }
        ]
    }
];
