import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

const STATUS = {
    PENDING: "Vacant Possession Notice",
    COMPLETED: "Completed"
};

async function getPendingVPList({ queries = {}, page = 1, perPage = 30 }) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,appUser";
    try {
        const URL = `${developerAPI}/project-purchase/vacant-possession-list`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                status: STATUS.PENDING,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get Pending VP List)`);
        throw error;
    }
}
async function getCompletedVPList({ queries = {}, page = 1, perPage = 30 }) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,appUser";
    try {
        const URL = `${developerAPI}/project-purchase/vacant-possession-list`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                status: STATUS.COMPLETED,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get Completed VP List)`);
        throw error;
    }
}

async function getVPDetail(id) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,projectPurchaseLawyer,appUser";
    try {
        const URL = `${developerAPI}/project-purchase/bookings/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] GetVP Detail (ID ${id})`);
        throw error;
    }
}
async function completeVP(id) {
    try {
        const URL = `${developerAPI}/project-purchase/${id}/completed`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Complete VP (ID ${id})`);
        throw error;
    }
}

export default {
    getPendingVPList,
    getCompletedVPList,
    getVPDetail,
    completeVP
};
