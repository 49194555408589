import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { ProjectBankLoanModel } from "@/models";

async function getBankLoans({
    queries = {},
    page = 1,
    perPage = 15,
    include = [
        "appUser",
        "projectUnit",
        "projectPurchase.agent",
        "projectPurchaseReservation"
    ]
}) {
    try {
        const URL = `${developerAPI}/bank-loans`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Bank Loans`);
        throw error;
    }
}

async function getBankLoan(id) {
    try {
        let include = [
            "appUser",
            "projectUnit",
            "projectPurchase.agent",
            "project",
            "loanApplicationDocuments",
            "projectPurchaseReservation",
            "resubmitLoanApplicationDocuments"
        ];

        const URL = `${developerAPI}/bank-loans/${id}`;

        const response = await axios.get(`${URL}?include=${include.join(",")}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Bank Loan (id: ${id})`);
        throw error;
    }
}

async function verifyLead(id) {
    try {
        const URL = `${developerAPI}/bank-loans/${id}/submitted`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Verify Lead (id: ${id})`);
        throw error;
    }
}

async function declineLead(id, payload) {
    try {
        payload = ProjectBankLoanModel.putDeclinePayload(payload);

        const URL = `${developerAPI}/bank-loans/${id}/request-additional-doc`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Decline Lead (id: ${id})`);
        throw error;
    }
}
async function approveLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putApproveLAPayload(payload);

        const URL = `${developerAPI}/bank-loans/${id}/approved`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Approve LA (id: ${id})`);
        throw error;
    }
}

async function rejectLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putRejectPayload(payload);

        const URL = `${developerAPI}/bank-loans/${id}/rejected`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Reject LA (id: ${id})`);
        throw error;
    }
}

async function confirmLOSigned(id, payload) {
    try {
        const URL = `${developerAPI}/bank-loans/${id}/signed-lo`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Confirmed LO Signed (id: ${id})`);
        throw error;
    }
}

// ========================== Other Mortgage Methods ===========================
async function getOtherMethodMortgages(queries = {}, page = 1, perPage = 15) {
    let include = [
        "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation",
        "projectPurchaseMortgage.projectPurchase.agent"
    ];
    try {
        const URL = `${developerAPI}/project-purchase/mortgages/other`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Other Method Mortgages`);
        throw error;
    }
}

async function getOtherMethodMortgage(id) {
    let include = [
        "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation",
        "projectPurchaseMortgage.projectPurchase.agent",
        "projectPurchaseMortgage.projectPurchase.project",
        "projectPurchaseMortgage.projectPurchase.projectUnitType",
        "projectPurchaseMortgage.projectPurchase.projectUnitFloorPlan",
        "projectPurchaseMortgage.projectPurchase.projectUnit"
    ];
    try {
        const URL = `${developerAPI}/project-purchase/mortgages/other/${id}`;

        const response = await axios.get(`${URL}?include=${include.join(",")}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Mstaff] Get Other Method Mortgage (id ${id})`);
        throw error;
    }
}

export default {
    // Bank Loans
    getBankLoans,
    getBankLoan,
    verifyLead,
    declineLead,
    approveLA,
    rejectLA,
    confirmLOSigned,
    // Other Methods
    getOtherMethodMortgages,
    getOtherMethodMortgage
};
