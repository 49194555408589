import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import ProjectReservationsModel from "@/models/projectReservation";

async function getReservedUnits({ queries = {}, page = 1, perPage = 30 }) {
    let include = "project,projectUnitType,projectUnitFloorPlan";
    try {
        const URL = `${developerAPI}/project-purchase/project-purchase-units`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                status: "Reservation",
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Reserved Units`);
        throw error;
    }
}

async function getReservations(
    projectUnitId,
    {
        queries = {},
        page = 1,
        perPage = 30,
        include = "appUser,agent,project,projectUnit,projectUnitFloorPlan,projectUnitType,projectPurchaseReservation"
    }
) {
    try {
        const URL = `${developerAPI}/project-purchase/reservations`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include,
                projectUnitId: projectUnitId
            }
        });

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Unit Reservations [ID: ${projectUnitId}]`
        );
        throw error;
    }
}

async function getReservationDetail(id) {
    let include =
        "appUser,agent,project,projectUnit,projectUnitFloorPlan,projectUnitType,projectPurchaseReservation";
    try {
        const URL = `${developerAPI}/project-purchase/reservations/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Reservation Detail [ID: ${id}]`);
        throw error;
    }
}

async function approveReservation(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase/reservations/${id}/approved`;

        const response = await axios.put(
            URL,
            ProjectReservationsModel.postApprovePayload(payload)
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Approve Reservation [ID: ${id}]`);
        throw error;
    }
}

async function cancelReservation(id) {
    try {
        const URL = `${developerAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Cancel Reservation [ID: ${id}]`);
        throw error;
    }
}

export default {
    getReservedUnits,
    getReservations,
    getReservationDetail,
    approveReservation,
    cancelReservation
};
