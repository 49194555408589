import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getRoles({ queries = {}, page = 1, perPage = 30 }) {
    let include = "";
    try {
        const response = await axios.get(`${developerAPI}/manage-roles`, {
            params: { ...queries, page: page, limit: perPage, include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Developer CP] Get role list");
        throw error;
    }
}
async function getRole(id) {
    let include = "";
    try {
        const response = await axios.get(`${developerAPI}/manage-roles/${id}`, {
            params: { include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Get role (#${id})`);
        throw error;
    }
}

export default {
    getRoles,
    getRole
};
