import { convertNullIfEmpty } from "@/utils/null-check";

class AccountModel {
    static postPayload(e) {
        return {
            email: e.email,
            name: e.name,
            avatar: e.avatar || [],

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }

    static getToEditResponse(e) {
        return {
            email: e.user?.email || "",
            name: e.name,
            avatar: e.avatar || [],

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || "",

            developer: e.developer,
            rolePermission: e.rolePermission
        };
    }
}

export default AccountModel;
