import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getDefects({ queries = {}, page = 1, perPage = 30 }) {
    let include =
        "projectPurchase.projectPurchaseReservation,projectPurchase.projectPurchaseLawyer,projectPurchase.project,projectPurchase.projectUnitType,projectPurchase.projectUnit,projectPurchase.appUser";
    try {
        const response = await axios.get(
            `${developerAPI}/project-purchase-defects`,
            {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage,
                    include: include
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, "[MStaff CP] Get Defects");
        throw error;
    }
}

async function getDefect(id) {
    let include =
        "projectPurchaseDefectRejectForm,projectPurchase.projectPurchaseReservation,projectPurchase.projectPurchaseLawyer,projectPurchase.project,projectPurchase.projectUnitType,projectPurchase.projectUnit,projectPurchase.appUser";
    try {
        const response = await axios.get(
            `${developerAPI}/project-purchase-defects/${id}?include=${include}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Get Defect [ID: ${id}]`);
        throw error;
    }
}

async function approveDefect(id) {
    try {
        const response = await axios.put(
            `${developerAPI}/project-purchase-defects/${id}/in-progress`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Approve Defect [ID: ${id}]`);
        throw error;
    }
}
async function completeDefect(id) {
    try {
        const response = await axios.put(
            `${developerAPI}/project-purchase-defects/${id}/completed`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Complete Defect [ID: ${id}]`);
        throw error;
    }
}

async function rejectDefect(id, payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/project-purchase-defects/${id}/rejected`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Reject Defect [ID: ${id}]`);
        throw error;
    }
}

export default {
    getDefects,
    getDefect,
    approveDefect,
    completeDefect,
    rejectDefect
};
