import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getNotifications(
    queries = { page: 1, perPage: 10, seen: null }
) {
    try {
        const response = await axios.get(`${developerAPI}/notification`, {
            params: {
                page: queries.page,
                limit: queries.perPage,
                seen: queries.seen
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Get Notifications`);
        throw error;
    }
}
async function getNotification(id) {
    try {
        const response = await axios.get(`${developerAPI}/notification/${id}`);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Get Notification (#${id})`);
        throw error;
    }
}
async function markAsSeen(id) {
    try {
        const response = await axios.put(
            `${developerAPI}/notification/${id}/seen`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Notif. Mark as seen`);
        throw error;
    }
}

export default {
    getNotifications,
    getNotification,
    markAsSeen
};
