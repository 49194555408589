import { account as accountAPI } from "@/api";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

async function isAllowed(permissions, permissionToCheck) {
    let permissionModule = permissionToCheck.split(".")[0];
    // Check if permission module is enabled
    if (permissions[permissionModule]?.enable) {
        // Check if permission has sub permissions
        if (permissionToCheck.split(".")[1]) {
            return _get(permissions, permissionToCheck) ?? false;
        }
        return permissions[permissionModule].enable;
    }
    return false;
}

export default {
    namespaced: true,
    state: () => {
        return {
            permissions: {}
        };
    },
    getters: {
        getPermissions(state) {
            return state.permissions;
        }
    },
    mutations: {
        setPermissions(state, permissions) {
            this._vm.$set(state, "permissions", permissions);
        }
    },
    actions: {
        async getProfile({ commit }) {
            let data = await accountAPI.getProfile();
            return data;
        },
        async editProfile({ commit }, payload) {
            let data = await accountAPI.editProfile(payload);
            return data;
        },
        async fetchPermissions({ commit }) {
            try {
                let data = await accountAPI.getPermission();
                commit("setPermissions", data);
            } catch (error) {
                //
            }
        },
        async checkPermission({ state, dispatch }, permissionToCheck) {
            if (_isEmpty(state.permissions)) {
                await dispatch("fetchPermissions");
            }
            return isAllowed(state.permissions, permissionToCheck);
        }
    }
};
