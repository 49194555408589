<template>
  <div id="app">
    <router-view />
    <loader v-show="isLoading"></loader>
    <!-- vue-notification -->
    <notifications class="m-2" group="alert" position="top center" :max="3" />

    <PortalTarget name="dropdown-list" class="dropdown-portal"></PortalTarget>
  </div>
</template>

<script>
import Loader from "@/components/GlobalComponents/LoaderComponent/Loader";

/* import firebase from "firebase/app";
import messaging from "firebase/messaging"; */

export default {
  name: "App",
  components: {
    Loader
  },
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    }
  }
};
</script>

<style lang="scss">
// Vue Notification overwrite
#app {}