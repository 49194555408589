import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getClients({
    queries = {},
    page = 1,
    perPage = 15,
    include = ""
}) {
    let defaultInclude =
        "agent,appUser,projectPurchaseLawyer,projectPurchaseReservation";

    try {
        const URL = `${developerAPI}/project-purchase-lawyers`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: `${defaultInclude}${include}`
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get Purchase Clients`);
        throw error;
    }
}

async function getClient(id) {
    let include =
        "agent,projectPurchaseLawyer,projectPurchaseReservation,projectPurchaseBooking,projectPurchaseMortgage.mortgageMethods.bankLoans";
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get Purchase Client (id:${id})`);
        throw error;
    }
}

async function setSPADate(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/select-sign-spa-date`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Set SPADate (id: ${id})`);
        throw error;
    }
}
async function setLADate(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/select-sign-la-date`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Set LADate (id: ${id})`);
        throw error;
    }
}
async function signSPA(id) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/sign-spa`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Sign SPA (id: ${id})`);
        throw error;
    }
}
async function signLA(id) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/sign-la`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Sign LA (id: ${id})`);
        throw error;
    }
}
async function payStampSPA(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/spa-pay-stamp-duty`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Pay Stamp SPA (id: ${id})`);
        throw error;
    }
}
async function payStampLA(id, payload) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/la-pay-stamp-duty`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Pay Stamp LA (id: ${id})`);
        throw error;
    }
}

async function markWithLA(id) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/la-confirmed`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Mark sale as without LA  (id: ${id})`);
        throw error;
    }
}

async function markWithoutLA(id) {
    try {
        const URL = `${developerAPI}/project-purchase-lawyers/${id}/without-la`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Mark sale as with LA (id: ${id})`);
        throw error;
    }
}
export default {
    getClients,
    getClient,
    setSPADate,
    setLADate,
    signSPA,
    signLA,
    payStampSPA,
    payStampLA,
    markWithLA,
    markWithoutLA
};
