import Vue from "vue";
import Vuex from "vuex";

import login from "./login";
import Sidebar from "@/store/misc/sidebar";

import ManageCountries from "@/store/manageCountries";
import ManageStates from "@/store/manageStates";
import ManageCities from "@/store/manageCities";
import ManageAreas from "@/store/manageAreas";
import manageBuildings from "@/store/manageBuildings";

import ManageAccount from "@/store/manageAccount";
import { notification as notificationAPI } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loaderQueue: [],
        hasUnreadNotification: false
    },
    mutations: {
        clearIsLoading(state) {
            state.loaderQueue = [];
        },
        setIsLoading(state, isLoading) {
            if (isLoading) {
                state.loaderQueue.push(state.loaderQueue.length + 1);
            } else {
                state.loaderQueue.pop();
            }
        },
        setHasUnreadNotification(state, bool) {
            state.hasUnreadNotification = bool;
        }
    },
    getters: {
        getIsLoading(state) {
            if (state.loaderQueue.length > 0) {
                return true;
            }
            return false;
        },
        getHasUnreadNotification(state) {
            return state.hasUnreadNotification;
        }
    },
    actions: {
        async checkHasUnreadNotification({ commit }) {
            let data = await notificationAPI.getNotifications({
                page: 1,
                perPage: 1,
                seen: false
            });
            commit("setHasUnreadNotification", data.data.length > 0);
        }
    },
    modules: {
        login: login,
        sidebar: Sidebar,

        manageAccount: ManageAccount,

        manageCountries: ManageCountries,
        manageStates: ManageStates,
        manageCities: ManageCities,
        manageAreas: ManageAreas,
        manageBuildings: manageBuildings
    }
});
