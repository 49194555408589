export default [
    {
        path: "/projects",
        name: "ManageProjects",
        component: () => import("@/views/manageProjects/index"),
        meta: {
            auth: true
        }
    },
    // {
    //     path: "/projects/add",
    //     name: "ManageProjectsAdd",
    //     component: () => import("@/views/manageProjects/add/index"),
    //     meta: {
    //         auth: true,
    //         permission: "project.create"
    //     }
    // },
    {
        path: "/projects/edit/:id",
        name: "ManageProjectsEdit",
        component: () => import("@/views/manageProjects/edit/_edit"),
        meta: {
            auth: true,
            permission: "manageProjects.modify"
        }
    },
    {
        path: "/projects/details/:id",
        name: "ManageProjectsDetails",
        component: () => import("@/views/manageProjects/detail/_detail"),
        meta: {
            auth: true
        }
    },
    {
        path: "/projects/:projectId/unit-type/:id",
        name: "ManageProjectUnitTypesDetails",
        component: () =>
            import("@/views/manageProjectUnitTypes/detail/_detail"),
        meta: {
            auth: true
        }
    },
    {
        path: "/projects/:projectId/unit-type/:unitTypeId/floor-plan/:id",
        name: "ManageProjectFloorPlanDetails",
        component: () =>
            import("@/views/manageProjectFloorPlans/detail/_detail"),
        meta: {
            auth: true
        }
    },
    // Allocate Bankers
    {
        path: "/projects/:id/allocate-bankers",
        name: "ProjectAllocateBankers",
        component: () => import("@/views/manageProjects/allocateBankers"),
        meta: {
            auth: true,
            permission: "manageProjectSales"
        }
    },
    // Allocate Lawyers
    {
        path: "/projects/:id/allocate-lawyers",
        name: "ProjectAllocateLawyers",
        component: () => import("@/views/manageProjects/allocateLawyers"),
        meta: {
            auth: true,
            permission: "manageProjectSales"
        }
    }
];
