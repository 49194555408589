import axios from "axios";
import { developerAPI, API } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProfile() {
    let include = "rolePermission";
    try {
        const response = await axios.get(
            `${developerAPI}/project-marketing-staff-profile?include=${include}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Get Profile`);
        throw error;
    }
}
async function editProfile(payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/project-marketing-staff-profile`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Edit Profile`);
        throw error;
    }
}
async function changePassword(payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/project-marketing-staff-profile/password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Change Password`);
        throw error;
    }
}
async function forgetPassword(email) {
    try {
        const response = await axios.post(`${API}/reset-password`, {
            type: "project-marketing-staff",
            email: email
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff CP] Forget Password`);
        throw error;
    }
}

async function getPermission() {
    try {
        const URL = `${developerAPI}/project-marketing-staff-profile/permission`;
        const response = await axios.get(URL);

        return response.data;
    } catch (error) {
        throw error;
    }
}

export default {
    getProfile,
    editProfile,
    changePassword,
    forgetPassword,
    getPermission
};
