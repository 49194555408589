import store from "../../store";

export default async function permission({ to, next }) {
    if (to.matched.some((record) => record.meta.permission)) {
        let allowed = await store.dispatch(
            "manageAccount/checkPermission",
            to.meta.permission
        );
        allowed
            ? next()
            : next({ name: "NotFound", params: [to.path], replace: true });
    } else {
        next();
    }
}
