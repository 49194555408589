import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getUsers({ queries = {}, page = 1, perPage = 30 }) {
    let include = "rolePermission";
    try {
        const response = await axios.get(`${developerAPI}/manage-users`, {
            params: { ...queries, page: page, limit: perPage, include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Developer CP] Get user list");
        throw error;
    }
}
async function getUser(id) {
    let include = "rolePermission";
    try {
        const response = await axios.get(`${developerAPI}/manage-users/${id}`, {
            params: { include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Get User (ID: ${id})`);
        throw error;
    }
}

async function createUser(payload) {
    try {
        const response = await axios.post(
            `${developerAPI}/manage-users`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Create User`);
        throw error;
    }
}

async function updateUser(userId, payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/manage-users/${userId}`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Update User (#${userId})`);
        throw error;
    }
}

async function updateUserPassword(userId, payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/manage-users/${userId}/update-password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Update User Password (#${userId})`);
        throw error;
    }
}

async function updateUserStatus(userId, payload) {
    try {
        const response = await axios.put(
            `${developerAPI}/manage-users/${userId}/update-active`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Developer CP] Update User Status (#${userId})`);
        throw error;
    }
}

export default {
    getUsers,
    getUser,
    createUser,
    updateUser,
    updateUserPassword,
    updateUserStatus
};
