import axios from "axios";
import { developerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProjectSales({ queries = {}, page = 1, perPage = 30 }) {
    let include = [
        "projectPurchaseReservation",
        "projectPurchaseBooking",
        "projectPurchaseLawyer",
        "projectPurchaseTracking",
        "project",
        "projectUnit",
        "agent",
        "projectUnitFloorPlan",
        "projectUnitType",
        "appUser"
    ];
    try {
        const response = await axios.get(`${developerAPI}/project-purchase`, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get All Project Sales)`);
        throw error;
    }
}

async function getTrackingData(id) {
    let include = [
        "projectPurchaseTrackingLogs",
        "projectPurchaseTracking",
        "projectPurchaseReservation",
        "projectPurchaseBooking",
        "projectPurchaseLawyer",
        "project",
        "agent",
        "projectUnit",
        "projectUnitType",
        "projectUnitFloorPlan",
        "appUser",
        "bankLoans.banker"
    ];
    try {
        const response = await axios.get(
            `${developerAPI}/project-purchase/bookings/${id}?include=${include.join(
                ","
            )}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[MStaff] Get Proj. Tracking Data (ID ${id})`);
        throw error;
    }
}

async function cancelProjectSales(id) {
    try {
        const URL = `${developerAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Dev CP] Cancel Proj. Sales [ID: ${id}]`);
        throw error;
    }
}

async function updateBookingDocuments(id, payload) {
    try {
        let URL = `${developerAPI}/project-purchase/bookings/${id}/update`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Dev CP] Update Booking Documents [ID: ${id}]`);
        throw error;
    }
}

async function updateSPADueDate(id, payload) {
    try {
        let URL = `${developerAPI}/project-purchase/${id}/update-spa-due-date`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default {
    getProjectSales,
    getTrackingData,
    cancelProjectSales,
    updateBookingDocuments,
    updateSPADueDate
};
